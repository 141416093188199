import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingConfig, intl } = props;
  const { listingFields } = listingConfig || {};

  if (!publicData || !listingConfig) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, includeForListingTypes, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(listingType);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
          ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
          : schemaType === 'long'
            ? filteredConfigs.concat({ key, value, label })
            : filteredConfigs;
    }
    return filteredConfigs;
  };

  const {
    brand,
    model,
    manufactureYear,
    enginePower,
    noOfDoors,
    transmissionType,
    gearboxType,
    fuelType,
    bodyType,
    vehicleCondition,
    vehicleOrigin,
    kilometers,
    accidentFree,
    mfk
  } = publicData;

  const capitalizedBrand = brand ? (brand.charAt(0).toUpperCase() + brand.slice(1)) : '';
  const capitalizedModel = model ? (model.charAt(0).toUpperCase() + model.slice(1)) : '';

  const existingListingFields = listingFields.reduce(pickListingFields, []);

  return (
    <>
      <div className={css.brandAndModel}>{capitalizedBrand + " " + capitalizedModel}</div>
      <div className={css.details}>
        <div>
          <label><FormattedMessage id="OrderPanel.manufactureYearLabel" /></label> {manufactureYear}
        </div>
        <div>
          <label><FormattedMessage id="OrderPanel.bodyTypeLabel" /></label> <FormattedMessage id={`OrderPanel.bodyType.${bodyType}`} />
        </div>
        {(enginePower && enginePower !== undefined) &&
          <div>
            <label><FormattedMessage id="OrderPanel.enginePowerLabel" /></label> {enginePower}
          </div>
        }
        {(noOfDoors && noOfDoors !== undefined) &&
          <div>
            <label><FormattedMessage id="OrderPanel.noOfDoorsLabel" /></label> {noOfDoors}
          </div>
        }
        {(transmissionType && transmissionType !== undefined) &&
          <div>
            <label><FormattedMessage id="OrderPanel.transmissionTypeLabel" /></label><FormattedMessage id={`OrderPanel.transmissionType.${transmissionType}`} />
          </div>
        }
        {(gearboxType && gearboxType !== undefined) &&
          <div>
            <label><FormattedMessage id="OrderPanel.gearboxTypeLabel" /></label> <FormattedMessage id={`OrderPanel.gearboxType.${gearboxType}`} />
          </div>
        }
        <div>
          <label><FormattedMessage id="OrderPanel.fuelTypeLabel" /></label>  <FormattedMessage id={`OrderPanel.fuelType.${fuelType}`} />
        </div>
        <div>
          <label><FormattedMessage id="OrderPanel.vehicleConditionLabel" /></label> <FormattedMessage id={`OrderPanel.vehicleCondition.${vehicleCondition}`} />
        </div>
        <div>
          <label><FormattedMessage id="OrderPanel.vehicleOriginLabel" /></label> <FormattedMessage id={`OrderPanel.vehicleOrigin.${vehicleOrigin}`} />
        </div>
        <div>
          <label><FormattedMessage id="OrderPanel.mfkLabel" /></label> <FormattedMessage id={`OrderPanel.mfk.${mfk}`} />
        </div>
        <div>
          <label><FormattedMessage id="OrderPanel.kilometersLabel" /></label> {kilometers}
        </div>
        <div>
          <label><FormattedMessage id="OrderPanel.accidentFreeLabel" /></label> <FormattedMessage id={`OrderPanel.accidentFree.${accidentFree}`} />
        </div>
      </div>
    </>
  );

  {/*
  <div className={css.sectionDetails}>
    <Heading as="h2" rootClassName={css.sectionHeading}>
      <FormattedMessage id="ListingPage.detailsTitle" />
    </Heading>
    <ul className={css.details}>
      {existingListingFields.map(detail => (
        <li key={detail.key} className={css.detailsRow}>
          <span className={css.detailLabel}>{detail.label}</span>
          <span>{detail.value}</span>
        </li>
      ))}
    </ul> 
  </div>
*/}
};

export default SectionDetailsMaybe;
